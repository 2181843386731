<div class="overflow-auto overflow-auto-table">
  <govuk-table [columns]="tableColumns()" [data]="items()">
    <ng-template let-column="column" let-row="row">
      @switch (column.field) {
        @case ('taskType') {
          <a [routerLink]="row | itemLink" class="govuk-link">
            {{ row[column.field] | itemName: row.requestId?.split('-')[1] }}
          </a>

          <div class="govuk-!-margin-top-2 govuk-!-margin-bottom-2">
            @if (row.isNew) {
              <govuk-tag>New</govuk-tag>
            }
          </div>
        }

        @case ('requestType') {
          {{ row[column.field] | itemType }}
        }

        @case ('taskAssignee') {
          {{ !row[column.field] ? unassignedLabel() : (row[column.field] | userFullName) }}
        }

        @case ('daysRemaining') {
          {{ row[column.field] | daysRemaining }}
        }

        @case ('businessId') {
          {{ row[column.field] }}
        }

        @case ('accountName') {
          {{ row[column.field] }}
        }

        @default {
          {{ row[column.field] }}
        }
      }
    </ng-template>
  </govuk-table>
</div>
